import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"

class Page extends Component {
  render() {
    // console.log(this.props.pageContext)
    const blocks = buildBlocks(
      this.props.data.wp.page.blocks,
      {},
      this.props.pageContext,
      this.props.data.wp.page.id
    )

    let faqs = null;

    this.props.data.wp.page.blocks.map((block) => {
      if(block.name === "acf/faqs") {
        faqs = block.blockFaqs.faqs;
      }
    });

    return (
      <Layout
        meta={this.props.data.wp.page.Meta}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wp.page.PageCTA}
        title={this.props.data.wp.page.title}
        themeOptions={this.props.data.wp.themeOptions}
        ii={this.props.data.wp.themeOptions.ThemeOptions.individualInnsBranding}
        pageContext={this.props.pageContext}
        faqs={faqs}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: ID!) {
    wp {
      page(id: $id) {
        id
        slug
        title
        Meta {
          fieldGroupName
          metaDescription
          metaTitle
          ogDescription
          ogTitle
          twitterCardType
          twitterDescription
          twitterTitle
          twitterImage {
            uri
            sourceUrl
          }
          ogImage {
            uri
            sourceUrl
          }
        }
        PageCTA {
          actionType
          ctaText
          fieldGroupName
          link {
            url
          }
        }
        ... on wp_BlockEditorContentNode {
          blocks {
            ...CoreBlock
            ...CoreFields
          }
        }
      }
      themeOptions {
        accommodationActive
        roomBookingCTAText
        roomBookingImage
        roomBookingImageText
        roomBookingLink
        tableBookingAccountId
        tableBookingActive
        tableBookingBrandId
        tableBookingCTAText
        tableBookingImage
        tableBookingImageText
        tableBookingRestaurantId
        liveResSiteId
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          individualInnsBranding
        }
      }
    }
  }
`

export default Page
